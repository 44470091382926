import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { cdaStore } from '@/utils/store-accessor';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
let List = class List extends Vue {
    constructor() {
        super(...arguments);
        this.value = 'recent';
        this.headers = [
            {
                text: 'Nome',
                sortable: true,
                value: 'nome',
                align: 'left',
            },
            {
                text: 'Entrada',
                sortable: true,
                value: 'total_entrada',
                align: 'left',
            },
            {
                text: 'Saída',
                sortable: true,
                value: 'total_saida',
                align: 'left',
            },
            {
                text: 'Saldo',
                sortable: true,
                value: 'total_saldo',
                align: 'left',
            },
        ];
        this.selectPeriodDate = null;
        this.search = '';
        this.loading = false;
        this.items = [];
    }
    async buscar() {
        this.loading = true;
        this.items = await cdaStore.getCadernoCda({
            data_inicial: this.selectPeriodDate[0],
            data_final: this.selectPeriodDate[1],
        });
        this.loading = false;
    }
};
List = __decorate([
    Component({
        components: {
            PeriodSelectorComponent,
        },
    })
], List);
export default List;
